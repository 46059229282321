import React, { Suspense } from "react";
import Header from "./Header/Header";
import Footer from "./Footer/Footer";
import WhatsApp from "./WhatsApp/WhatsApp";

function Layout({ children }) {

  return (
    <>
      <Header />
      <main>
        <Suspense fallback={<h1>Loading..</h1>}>
          {children}

        </Suspense>
      </main>
      <Footer />
      <WhatsApp />
    </>
  );
}

export default Layout;
