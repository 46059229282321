export const company = [
  {
    id: 1,
    link: "/ana-sehife",
    title: "Ana səhifə",
  },

  {
    id: 2,
    link: "/texnika-satisi",
    title: " Texnika satışı",
  },
  {
    id: 3,
    link: "/aqroservis",
    title: "Aqroservis",
  },

  // {
  //   id: 4,
  //   link: "/diger-xidmetler",
  //   title: "Digər xidmətlər",
  // },
  {
    id: 5,
    link: "/haqqimizda",
    title: "Haqqımızda",
  },
  // {
  //   id: 6,
  //   link: "/bloq",
  //   title: "Bloq ",
  // },
  {
    id: 7,
    link: "/elaqe",
    title: "Əlaqə",
  },
];
export const catolog = [
  { id: Date.now(), title: "Traktorlar  ", link: "/traktorlar" },
  { id: Date.now(), title: "Kombaynlar", link: "/kombaynlar" },
  { id: Date.now(), title: "Aqreqatlar", link: "/aqreqatlar" },
];
export const contact = [
  { id: 21, title: "+994 (51) 230 00 30", link: "tel:+994512300030" },
  { id: 22, title: "www.agrotexnika.az ", link: "/ana-sehife" },
  {
    id: 23,
    title: "sales@agtotexnika.az",
    link: "mailto:sales@agtotexnika.az",
  },
];
