import React, { useEffect, useState } from 'react';
import styles from './HeaderTop.module.scss';
import {
  SVGepmtyWp,
  SVGfaceBook,
  SVGinstagram,
} from '../../../../../assests/icons';
import { mockHeaderItems } from '../../../../../mock/mockHeaderItems';
import { Link, useLocation } from 'react-router-dom';

function HeaderTop() {
  const location = useLocation().pathname;
  const [activeLink, setActiveLink] = useState(null);

  useEffect(() => {
    const activeItem = mockHeaderItems.find((item) => item.url === location);
    const initial = mockHeaderItems.find(
      (item) => item.url === '/texnika-satisi'
    );
    if (location === '/') {
      setActiveLink(initial.id);
    }
    if (activeItem) {
      setActiveLink(activeItem.id);
    }
  }, [location]);
  return (
    <div className={styles.headerTop}>
      <div className={styles.headerTop__left}>
        {mockHeaderItems?.map((item) => (
          <Link
            style={{ color: activeLink === item.id ? '#0AA349' : 'inherit' }}
            key={item.id}
            to={item.url}
          >
            <span>|</span>
            {item.title}
          </Link>
        ))}
      </div>
      <div className={styles.headerTop__right}>
        <a href="https://www.facebook.com/aqrotexnika.az" target="_blank">
          <SVGfaceBook />
        </a>
        <a href="https://instagram.com/aqrotexnika.az" target="_blank">
          <SVGinstagram />
        </a>
      </div>
    </div>
  );
}

export default HeaderTop;
