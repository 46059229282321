import { useState } from 'react';

import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { baseApiSelector } from '../redux/selectors';

const useUsers = () => {
  const baseApi = useSelector(baseApiSelector);

  const [defaultHeaders, setDefaultHeaders] = useState({
    'Content-Type': 'application/json',
  });
  const interceptor = ({ url, ...config }) => {
    return new Promise((resolve, reject) => {
      axios({ ...config, headers: defaultHeaders, url: baseApi + url })
        .then((data) => {
          return resolve(data.data);
        })
        .catch((err) => {
          if (err?.response?.status === 401) {
            return (window.location.href = '/logout');
          }
          reject(err);
        });
    });
  };

  const interceptorAWS = ({ url, ...config }) => {
    return new Promise((resolve, reject) => {
      axios({ ...config, url })
        .then(({ data }) => {
          return resolve(data);
        })
        .catch((err) => reject(err));
    });
  };

  //

  const getProducts = (filters = {}, offset, limit, search = '') => {
    return interceptor({
      method: 'POST',
      url: '/products',
      data: { filters, offset, limit, search },
    });
  };

  const getProductById = (id) => {
    return interceptor({
      method: 'GET',
      url: `/product/${id}`,
      data: {},
    });
  };

  const getLeasingById = (id, initial_payment_percentage, max_leasing_time) => {
    return interceptor({
      method: 'POST',
      url: '/leasing',
      data: { id, initial_payment_percentage, max_leasing_time },
    });
  };

  const getContactInfo = () => {
    return interceptor({
      method: 'GET',
      url: '/contact',
      data: {},
    });
  };

  const getTopFive = () => {
    return interceptor({
      method: 'GET',
      url: `/topfive`,
      data: {},
    });
  };
  const getCategories = () => {
    return interceptor({
      method: 'GET',
      url: `/category`,
      data: {},
    });
  };

  const getFilters = (value) => {
    return interceptor({
      method: 'POST',
      url: `/filters`,
      data: { value },
    });
  };

  const postToService = (id = null, options) => {
    return interceptor({
      method: 'POST',
      url: `/page/service`,
      data: { id, options },
    });
  };

  const getCategoryCatalog = () => {
    return interceptor({
      method: 'GET',
      url: `/categoryforcatalog`,
      data: {},
    });
  };

  const getServiceCatalog = () => {
    return interceptor({
      method: 'GET',
      url: `/serviceforcatalog`,
      data: {},
    });
  };

  const postOrder = (
    name,
    last_name,
    phone,
    fermar_id,
    area_dimension,
    plant_id,
    service_type_id,
    soil_type_id,
    irrigation_type_id,
    combine_brand,
    region_id,
    village_id,
    date
  ) => {
    return interceptor({
      method: 'POST',
      url: `/order`,
      data: {
        name,
        last_name,
        phone,
        fermar_id,
        area_dimension,
        plant_id,
        service_type_id,
        soil_type_id,
        irrigation_type_id,
        combine_brand,
        region_id,
        village_id,
        date,
      },
    });
  };

  const interestedProduct = (name, surname, phone, region, product_id) => {
    return interceptor({
      method: 'POST',
      url: `/interest/product`,
      data: { name, surname, phone, region, product_id },
    });
  };

  const interestedService = (
    name,
    surname,
    phone,
    region,
    area_dimension,
    plant_id,
    service_type_id
  ) => {
    return interceptor({
      method: 'POST',
      url: `/interest/service`,
      data: {
        name,
        surname,
        phone,
        region,
        area_dimension,
        plant_id,
        service_type_id,
      },
    });
  };

  const getFaq = () => {
    return interceptor({
      method: 'GET',
      url: `/faq`,
      data: {},
    });
  };

  const getSlider = () => {
    return interceptor({
      method: 'GET',
      url: `/slider`,
      data: {},
    });
  };

  const getBanner = () => {
    return interceptor({
      method: 'GET',
      url: `/banner`,
      data: {},
    });
  };

  const getService = () => {
    return interceptor({
      method: 'GET',
      url: `/page/service`,
      data: {},
    });
  };

  const getAbout = () => {
    return interceptor({
      method: 'GET',
      url: `/page/about`,
      data: {},
    });
  };

  return {
    //
    getProducts,
    getProductById,
    getContactInfo,
    getLeasingById,
    getCategoryCatalog,
    getServiceCatalog,
    getCategories,
    getFilters,
    getTopFive,
    postToService,
    postOrder,
    interestedProduct,
    interestedService,
    getFaq,
    getSlider,
    getBanner,
    getService,
    getAbout,
  };
};

export default useUsers;
