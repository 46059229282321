export const baseApiSelector = (state) => state.baseApiSlice.baseApi;

export const productsSelector = (state) => state.productSlice.products;

export const productSelector = (state) => state.productSlice.product;

export const productIdSelector = (state) => state.productSlice.productId;

export const isEmptySelector = (state) => state.productSlice.isEmpty;

export const initialSelector = (state) => state.productSlice.initial;

export const brandsSelector = (state) => state.brandsSlice.brands;

export const contactsSelector = (state) => state.contactSlice.contact;

export const categoriesSelector = (state) => state.categorySlice.categories;

export const topFiveSelector = (state) => state.topFiveSlice.topFive;

export const servicePlantsSelector = (state) =>
  state.servicesSlice.servicePlants;

export const serviceRegionsSelector = (state) =>
  state.servicesSlice.serviceRegions;

export const serviceTypesSelector = (state) => state.servicesSlice.serviceTypes;

export const serviceAreasSelector = (state) => state.servicesSlice.serviceAreas;

export const serviceSoilTypesSelector = (state) =>
  state.servicesSlice.serviceSoilTypes;

export const serviceIrrigationTypesSelector = (state) =>
  state.servicesSlice.serviceIrrigationTypes;

export const serviceCombineModelsSelector = (state) =>
  state.servicesSlice.serviceCombineModels;

export const serviceDistrictsSelector = (state) =>
  state.servicesSlice.serviceDistricts;

export const selectedItemsSelector = (state) =>
  state.servicesSlice.selectedItems;

export const serviceTextSelector = (state) => state.servicesSlice.serviceText;

export const bannersSelector = (state) => state.bannerSlice.banners;

export const aboutSelector = (state) => state.aboutSlice.banners;
