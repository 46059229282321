import React from "react";

import styles from "./Banner.module.scss";


function Banner({ fixed, bannerImg }) {
  const className = `${styles.banner} ${(fixed === "left" && styles.banner__fixed__left) ||
    (fixed === "right" && styles.banner__fixed__right)
    }`;



  return (
    <div className={className}>
      <a className={styles.banner__link}>

        <img
          src={bannerImg} alt=""
          style={{ minHeight: 535 }}

        />
      </a>
    </div>
  );
}

export default Banner;
