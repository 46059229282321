import React from 'react';
import ReactDOM from 'react-dom/client';

import { Provider } from 'react-redux';
import { store } from './redux/store';
import reportWebVitals from './reportWebVitals';
import './index.css';

import App from './App';
import { BrowserRouter } from 'react-router-dom';
import ReactGA from 'react-ga4';

const root = ReactDOM.createRoot(document.getElementById('root'));
const TRACKING_ID = 'G-LJ6RN0FPLM';
ReactGA.initialize(TRACKING_ID);

// ReactGA.event({
//   category: 'User',
//   action: 'Created an Account',
// });

// ReactGA.exception({
//   description: 'An error ocurred',
//   fatal: true,
// });

root.render(
  <BrowserRouter>
    <Provider store={store}>
      <App />
    </Provider>
  </BrowserRouter>
);
const SendAnalytics = () => {
  ReactGA.send({
    hitType: 'pageview',
    page: window.location.pathname,
  });
};

reportWebVitals(SendAnalytics);
