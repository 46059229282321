import React from 'react';
import FooterList from './components/FooterList';
import CopyRight from './components/CopyRight';
import styles from './FooterStyle.module.scss';
import { catolog, company, contact } from '../../../mock/mockFooter';

export default function Footer() {
  return (
    <div className={styles.main}>
      <div className={styles.list}>
        <FooterList header="Birbaşa keçidlər" list={company} />
        <FooterList header="Kataloq" list={catolog} />
        <FooterList header="Əlaqələr" list={contact} />
      </div>
      <CopyRight />
    </div>
  );
}
