import React from "react";
import styles from "../FooterStyle.module.scss";

export default function CopyRight() {
  return (
    <div className={styles.copyRight}>
      Copyright © 2023 All rights reserved. Digitup LLC
    </div>
  );
}
