import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  baseApi: 'https://api.aqrotexnika.az/api',
  // baseApi: 'https://testapi.aqrotexnika.az/api',
};

const baseApiSlice = createSlice({
  name: 'baseApi',
  initialState,
});

export default baseApiSlice.reducer;
